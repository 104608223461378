import { Route, Redirect } from 'react-router-dom';
import { getToken } from 'Api/services';
import PropsTypes from 'prop-types';

export function GuardRoute(props) {
  const { component, path, redirect } = props;
  const isToken = getToken();

  return <Route exact path={path} render={() => (isToken ? component : <Redirect to={redirect} />)} />;
}

GuardRoute.propsTypes = {
  component: PropsTypes.element
};
