import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { GuardRoute } from 'Components/GuardRoute/GuardRoute';
import './App.scss';

const Home = React.lazy(() => import('Views/Home/Home'));
const Candidates = React.lazy(() => import('Views/Candidates/Candidates'));
const Vote = React.lazy(() => import('Views/Vote/Vote'));
const VoteSummary = React.lazy(() => import('Views/VoteSummary/VoteSummary'));
const Winner = React.lazy(() => import('Views/Winner/Winner'));

function App() {
  return (
    <div className="App">
      <React.Suspense fallback="Loading...">
        <Router>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/candidatos">
              <Candidates />
            </Route>
            <GuardRoute path="/vota" redirect="/candidatos" component={<Vote />} />
            <GuardRoute path="/resumen-votos" redirect="/candidatos" component={<VoteSummary />} />
            <Route exact path="/ganadores">
              <Winner />
            </Route>
          </Switch>
        </Router>
      </React.Suspense>
    </div>
  );
}

export default App;
