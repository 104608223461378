import axios from 'axios';
import { encrypt, normalizePassword } from '@dia-discapacitados/common';
import { baseUrl } from './config';

export const CAMPAIGN_STATES = {
  beforeCampaign: 'beforeCampaign',
  inCampaign: 'inCampaign',
  afterCampaign: 'afterCampaign',
  resultCampaign: 'resultCampaign'
};

export const getCampaignStatus = () =>
  axios.get(`${baseUrl}/campaign`).then((res) => {
    const currentDate = new Date();

    if (currentDate <= new Date(res.data.start)) {
      return CAMPAIGN_STATES.beforeCampaign;
    } else if (currentDate >= new Date(res.data.start) && currentDate <= new Date(res.data.end)) {
      return CAMPAIGN_STATES.inCampaign;
    } else if (currentDate > new Date(res.data.end) && currentDate < new Date(res.data.showResults)) {
      return CAMPAIGN_STATES.afterCampaign;
    } else {
      return CAMPAIGN_STATES.resultCampaign;
    }
  });

export const setDataStorage = (token, user) => {
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('user', user);
};

export const getToken = () => sessionStorage.getItem('token');

export const getUser = () => sessionStorage.getItem('user');

export const getCandidates = () => axios.get(`${baseUrl}/candidates`);

export const login = async ({ email, password }) => {
  const encryptedPwd = await encrypt(normalizePassword(password));

  return axios.post(`${baseUrl}/login`, { user: email, password: encryptedPwd });
};

export const sendVotes = (votes) => {
  const sendVotes = Object.keys(votes).map((item) => {
    return {
      id: item,
      points: votes[item]
    };
  });

  return axios.post(`${baseUrl}/vote`, { votes: sendVotes }, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const getVotes = () =>
  axios.get(`${baseUrl}/vote`, {
    headers: {
      Authorization: `Bearer ${getToken()}`
    }
  });

export const getWinner = () => axios.get(`${baseUrl}/winner`);
